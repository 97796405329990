import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Projects = () => {
    return (
        <div className="container">
            <div className="d-flex justify-content-center mb-2 fw-bolder fs-5 text-primary mt-3 mb-3">My projects</div>
            
            <div className="row">

                <div className="col">
                    <Card>
                        <Card.Body>
                            <Card.Title>User account management</Card.Title>
                            
                            <Card.Text>
                                Description loading...
                            </Card.Text>

                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <Link to="/projects" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Github</Link>
                                </div>

                                <div className="col d-flex justify-content-center">
                                    <Link to="/contact" type="button" className="btn btn-primary px-3 pt-2 pb-2 rounded-pill ">Demo</Link>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className="col">
                    <Card>
                        <Card.Body>
                            <Card.Title>Task management</Card.Title>
                            <Card.Text>
                               Description loading...
                            </Card.Text>

                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <Link to="/projects" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Github</Link>
                                </div>

                                <div className="col d-flex justify-content-center">
                                    <Link to="/contact" type="button" className="btn btn-primary px-3 pt-2 pb-2 rounded-pill ">Demo</Link>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className="col">
                    <Card>
                        <Card.Body>
                            <Card.Title>Bill management</Card.Title>
                            <Card.Text>
                                Description loading...
                            </Card.Text>

                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <Link to="/projects" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Github</Link>
                                </div>

                                <div className="col d-flex justify-content-center">
                                    <Link to="/contact" type="button" className="btn btn-primary px-3 pt-2 pb-2 rounded-pill ">Demo</Link>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
            </div>

            <div className="row mt-5">

                <div className="col">
                    <Card>
                        <Card.Body>
                            <Card.Title>Document management</Card.Title>
                            <Card.Text>
                                Description loading...
                            </Card.Text>

                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <Link to="/projects" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Github</Link>
                                </div>

                                <div className="col d-flex justify-content-center">
                                    <Link to="/contact" type="button" className="btn btn-primary px-3 pt-2 pb-2 rounded-pill ">Demo</Link>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className="col">
                    <Card>
                        <Card.Body>
                            <Card.Title>Personnal management</Card.Title>
                            <Card.Text>
                                Description management...
                            </Card.Text>

                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <Link to="/projects" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Github</Link>
                                </div>

                                <div className="col d-flex justify-content-center">
                                    <Link to="/contact" type="button" className="btn btn-primary px-3 pt-2 pb-2 rounded-pill ">Demo</Link>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
                <div className="col">
                    <Card>
                        <Card.Body>
                            <Card.Title>Statistics and report</Card.Title>
                            <Card.Text>
                               Description loading...
                            </Card.Text>

                            <div className="row">
                                <div className="col d-flex justify-content-center">
                                    <Link to="/projects" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Github</Link>
                                </div>

                                <div className="col d-flex justify-content-center">
                                    <Link to="/contact" type="button" className="btn btn-primary px-3 pt-2 pb-2 rounded-pill ">Demo</Link>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Projects