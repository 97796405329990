import React from "react";
import Typed from "react-typed"
import { Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";


const Home = () => {
    return (
        <div className="home-page">
            <div className="home-content">
                <div className=" row text-white text-center mb-3 ">
                    <h1 className="mt-5">Welcome to my page!</h1>
                </div>
                <div className="mt-2 mb-3">

                    <Typed className="text-white fs-5 d-flex justify-content-center"
                        strings={["My Skills:", "DevOps", "Spring Boot 2.x.x", "Thymeleaf", "React Js", "Bootstrap 5",
                            "Cloud deployement", "Web developement", "Web design", "Java", "Javascript", "CSS", "HTML"]}
                        typeSpeed={40} backSpeed={60} loop
                    />
                </div>

                <div className=" row text-white text-center mb-3 ">
                    <div className="row">

                        <div className="col-8">
                            <figure className="text-center">
                                <blockquote className="blockquote">
                                    
                                    <p className="m-5">
                                        I started programming since 2008. I have used several languages including PHP, C, Java, Javascript, Python. Today I use Java for the back-end, Javascript, HTML, CSS for the front-end and Python for the script.
                                        I have advanced knowledge in the cloud deployement.
                                        In my free time, I feed my blog or write a book.
                                        I speak French, English and German.
                                    </p>
                                </blockquote>
                                <figcaption className="blockquote-footer text-white">
                                    Shalom Et. Klussey, <cite title="Source Title">Web developer based in Germany</cite>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-4">
                            <Card style={{ width: '25rem' }}>
            
                                <Card.Body>
                                    <h5 className="text-primary">Contact me</h5>
                                    <Card.Text>
                                        <Form>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" placeholder="Your Name" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="name@getkluss.com" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control as="textarea" rows={3} />
                                            </Form.Group>
                                        </Form>
                                    </Card.Text>

                                    <div className="row">
                                        <div className="col d-grid gap-2">
                                            <Link to="/" type="button" className="btn btn-success px-3 pt-2 pb-2 mx-2 rounded-pill ">Send</Link>
                                        </div>

                                    </div>
                                </Card.Body>
                            </Card>
                        </div>

                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default Home