import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Projects from './components/Projects';

function App() {
  return (

    <Router>

      <Navbar />

      <Switch>
        <Route exact path="/" >
          <Home />
        </Route>

        <Route path="/projects">
          <Projects />
        </Route>

      </Switch>


    </Router>

  );
}


export default App;
