import React from "react";
import { Link } from "react-router-dom";


const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <div className="container">
                <Link to="/" className="navbar-brand">
                    <span className="fs-4 text-text-white-50 fw-bolder">Get Kluss</span>
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
                    <ul className="navbar-nav ">
                        <li className="nav-item active mx-1 px-1">
                            <Link to="/" className="nav-link text-white">Home</Link>
                        </li>

                        <li className="nav-item mx-1 px-1">
                            <Link to="/projects" className="nav-link text-white">Projects</Link>
                        </li>

                        <li className="nav-item mx-1 px-1">
                            <Link to="/blog" className="nav-link text-white">Blog</Link>
                        </li>
                      
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar